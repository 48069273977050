<!--
 * @Description:
 * @Version: 0.0.1
 * @Autor: Silence
 * @Date: 2023-04-28 00:39:08
 * @LastEditors:
 * @LastEditTime: 2023-04-28 00:39:08
-->
<template>
  <el-dialog
    ref="tk"
    lock-scroll
    :top="dialogtop"
    v-bind="$props"
    :visible.sync="visible"
    :show-close="showClose"
    :close-on-click-modal="isClickModel"
    append-to-body
  >
    <slot></slot>
    <template v-if="isFooter" #footer>
      <div v-if="$scopedSlots.footer">
        <slot name="footer"></slot>
      </div>
      <div v-else>
        <el-button v-if="isCancel" size="mini" @click="onCancel">{{
          cancelText
        }}</el-button>
        <el-button
          v-if="isConfirm"
          size="mini"
          type="primary"
          @click="onConfirm"
          >{{ confirmText }}</el-button
        >
      </div>
    </template>
  </el-dialog>
</template>
<script>
export default {
  name: "",
  components: {},
  props: {
    fullscreen: {
      type: Boolean,
      default: false,
    },
    title: String,
    value: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "50%",
    },
    height: {
      type: String,
      default: "400px",
    },
    isClickModel:{
      type:Boolean,
      default:true,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: "",
    },
    destroyOnClose: {
      type: Boolean,
      default: false,
    },
    closeOnClickModal: {
      type: Boolean,
      default: true,
    },
    minWidth: {
      type: String,
      default: "100px",
    },
    isFooter: {
      type: Boolean,
      default: true,
    },
    isConfirm: {
      type: Boolean,
      default: true,
    },
    isCancel: {
      type: Boolean,
      default: true,
    },
    cancelText: {
      type: String,
      default: "取 消",
    },
    confirmText: {
      type: String,
      default: "确 定",
    },
  },
  computed: {
    visible: {
      get() {
        if (this.value) {
          this.$nextTick(() => {
            this.onSetHeight();
          });
        }
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      dialogtop: "10vh", // 弹框距离顶部
      topMargin: 20, // 上边距
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 设置弹窗高度
    onSetHeight() {
      let bodyHeight = parseInt(document.body.clientHeight);
      let newHeight = 0;
      let suffix = "%";
      if (this.height.indexOf("px") > 0) {
        suffix = "px";
      }
      let numHeight = parseInt(this.height); // 弹框高度值
      if (suffix === "px") {
        newHeight = numHeight;
      } else {
        newHeight = parseInt((bodyHeight * numHeight) / 100);
      }

      let endHeight = newHeight;
      if (newHeight + this.topMargin * 2 > bodyHeight) {
        endHeight = bodyHeight - this.topMargin * 2;
        this.dialogtop = this.topMargin + "px";
      } else {
        this.dialogtop =
          (parseInt(bodyHeight) - parseInt(endHeight)) / parseInt(2) + "px";
      }
      try {
        if (!this.fullscreen) {
          this.$refs.tk.$el.firstChild.style.height = endHeight - 1 + "px";
          this.$refs.tk.$el.firstChild.style.marginBottom = this.dialogtop;
          this.$refs.tk.$el.firstChild.style.minWidth = this.minWidth;
        }
      } catch (error) {
        document.execCommand("Refresh");
      }
    },
    // 取消
    onCancel() {
      this.visible = false;
    },

    // 确定
    onConfirm() {
      this.$emit("handleConfirm");
    },
  },
};
</script>
<style lang="scss">
/* dialog设置 */
.el-dialog__header {
  padding: 10px;
  border-bottom: solid 1px #ccc;
}
.el-dialog__header .el-dialog__headerbtn {
  top: 10px;
}
.el-dialog__body {
  height: calc(100% - 115px);
}
.el-dialog__footer {
  padding: 10px !important;
  border-top: solid 1px #ccc;
}
</style>
