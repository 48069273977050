class vm {
  param = [];

  createContext(common) {
    this.param = Object.keys(common);
  }

  getFunc(code) {
    return new Function(this.param.toString(), code);
  }

  runInContext(code, common) {
    const fun = this.getFunc(code);
    return fun.apply(this, Object.values(common));
  }
}

export default new vm();
