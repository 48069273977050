<template>
  <el-menu :default-active="nowMenu.name">
    <el-menu-item
      v-for="(item, index) in menulist"
      :index="item.name"
      :key="index"
      @click="handleToMenu(item)"
    >
      <i :class="item.icon"></i>
      <span slot="title">{{ item.title }}</span>
    </el-menu-item>
  </el-menu>
</template>
<script>
import menulist from "@/router/adminRouter.js";
import { menuKey } from "@/settings.js";
import { setLocalStorage, getLocalStorage } from "@/util/storage";
import { getRouterInfo } from "@/util/throttle";

export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      menulist,
      nowMenu: {},
    };
  },
  watch: {
    $route(val) {
      setLocalStorage(menuKey, getRouterInfo(val.name));
      this.nowMenu = val;
    },
  },
  created() {},
  mounted() {
    this.nowMenu = getLocalStorage(menuKey);
    if (!this.nowMenu) {
      this.nowMenu = menulist[0];
      setLocalStorage(menuKey, this.nowMenu);
    }
  },
  methods: {
    /**
     * 打开菜单页面
     */
    handleToMenu(item) {
      this.$cloud.go(item.path);
    },
  },
};
</script>
<style lang="scss" scoped></style>
