import adminRouter from "@/router/adminRouter";
/**
 * Simple throttle function that executes a passed function only once in the specified timeout
 * @param handlerFunc
 * @param [timeout] the throttle interval
 */
export function throttle(handlerFunc, timeout = 66) {
  let resizeTimeout;
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      handlerFunc();
      // The actualResizeHandler will execute at a rate of 15fps
    }, timeout);
  }
}

/**
 *  @actions:  获取当前路由配置信息
 *  @content:
 *  @Autor: Silence
 *  @Date: 2023-04-26 09:43:57
 *  @params:
 *          name  router配置中的name  string
 **/
export function getRouterInfo(name) {
  console.log("路由筛选：", adminRouter);
  let routerinfo = {};
  // eslint-disable-next-line no-unused-vars
  var recursion = function (routerlist, name) {
    for (let i = 0; i < routerlist.length; i++) {
      let item = routerlist[i];
      if (item.children) {
        recursion(item.children, name);
      } else {
        if (item.name === name) {
          return (routerinfo = item);
        }
      }
    }
  };
  recursion(adminRouter, name);
  console.log("筛选出来的内容", routerinfo);
  return routerinfo;
}
