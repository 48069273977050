<!--
 * ======================================
 * 说明： 消息提醒
 * 作者： Silence
 * 文件： index.vue
 * 日期： 2023/5/15 15:13
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div>
    <el-popover
      placement="bottom"
      width="480"
      trigger="hover"
      v-model="visible"
    >
      <el-button
        type="text"
        slot="reference"
        class="mx-4"
        style="font-size: 14px"
        :class="`${textColor}`"
        icon="el-icon-message-solid"
        @click="visible = !visible"
        v-show="!userInfo.no && userInfo.role === 'teacher'"
      >
        <el-badge
          v-if="handleValue + appealValue"
          :value="handleValue + appealValue"
          :max="99"
          class="item"
        >
          申诉管理
        </el-badge>
        <span v-else>申诉管理</span>
      </el-button>
      <div :class="!roomId ? 'border-t border-gray-200' : ''">
        <div
          v-if="roomId"
          class="
            flex
            justify-center
            items-center
            border border-gray-200 border-b-0
          "
        >
          <span
            :class="`
            p-2
            flex-1
            text-center
            border-r border-gray-200
            cursor-pointer
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            ${activeId === 1 ? 'border-b-0' : 'border-b'}`"
            @click="activeId = 1"
            >处理</span
          >
          <span
            :class="`p-2
            flex-1
            text-center
            cursor-pointer
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
            ${activeId === 2 ? 'border-b-0' : 'border-b'}`"
            @click="activeId = 2"
            >申诉</span
          >
        </div>
        <div
          class="border border-gray-200 border-t-0 p-2"
          v-if="activeId === 1"
        >
          <el-row class="border-b border-gray-200">
            <el-col :span="4" class="p-1 text-center">教师</el-col>
            <el-col :span="4" class="p-1 text-center">学生</el-col>
            <el-col :span="12" class="p-1 text-center">评分项</el-col>
            <el-col :span="4" class="p-1 text-center">操作</el-col>
          </el-row>
          <el-row v-if="!handleData.length">
            <el-col :span="24" class="p-1 pt-3 text-center">暂无数据</el-col>
          </el-row>
          <el-row
            class="border-b border-gray-200"
            v-for="(item, i) in handleData"
            :key="i"
          >
            <el-col :span="4" class="p-1 pt-3 text-center">{{
              item.User ? item.User.username : ""
            }}</el-col>
            <el-col :span="4" class="p-1 pt-3 text-center">{{
              item.Student ? item.Student.username : ""
            }}</el-col>
            <el-col :span="12" class="p-1 pt-3 text-center"
              >{{ item.no
              }}{{
                item["targetedList"]
                  ? item["targetedList"].map((part) => part.name).join("，")
                  : ""
              }}</el-col
            >
            <el-col :span="4" class="p-1 text-center">
              <el-button
                v-if="item['status'] === 'YES'"
                type="text"
                @click="handleUpdate(item)"
                >修改</el-button
              >
            </el-col>
          </el-row>
        </div>
        <div
          class="border border-gray-200 border-t-0 p-2"
          v-if="activeId === 2"
        >
          <el-row class="border-b border-gray-200">
            <el-col :span="4" class="p-1 text-center">班级</el-col>
            <el-col :span="4" class="p-1 text-center">学生</el-col>
            <el-col :span="8" class="p-1 text-center">评分项</el-col>
            <el-col :span="4" class="p-1 text-center">状态</el-col>
            <el-col :span="4" class="p-1 text-center">操作</el-col>
          </el-row>

          <el-row v-if="!startData.length">
            <el-col :span="24" class="p-1 pt-3 text-center">暂无数据</el-col>
          </el-row>
          <el-row
            class="border-b border-gray-200"
            v-for="(item, i) in startData"
            :key="i"
          >
            <el-col :span="4" class="p-1 pt-3 text-center"
              >{{ item.Room.level
              }}{{
                item.Room.name.length > 1
                  ? item.Room.name
                  : "0" + item.Room.name
              }}</el-col
            >
            <el-col :span="4" class="p-1 pt-3 text-center">{{
              item.Student ? item.Student.username : ""
            }}</el-col>
            <el-col :span="8" class="p-1 pt-3 text-center"
              >{{ item.no
              }}{{
                item["targetedList"]
                  ? item["targetedList"].map((part) => part.name).join("，")
                  : ""
              }}</el-col
            >
            <el-col :span="4" class="p-1 pt-2 text-center">
              <el-tag type="danger" v-if="item.status === 'NO'">驳回</el-tag>
              <el-tag type="success" v-else-if="item.is_edit === 1"
                >已处理</el-tag
              >
              <el-tag type="primary" v-if="item.status === 'PROCESS'"
                >审核中</el-tag
              >
              <el-tag type="primary" v-else-if="item.is_edit === 0"
                >处理中</el-tag
              >
            </el-col>
            <el-col :span="4" class="p-1 text-center">
              <el-button
                type="text"
                v-if="item.is_edit === 1"
                @click="handleConfirm(item)"
                >确认</el-button
              ></el-col
            >
          </el-row>
        </div>
      </div>
    </el-popover>
    <appeal ref="appeal"></appeal>
  </div>
</template>
<script>
import appeal from "./appeal.vue";
import Cookies from "js-cookie";
export default {
  name: "MessageAppeal",
  components: { appeal },
  props: {
    textColor: {
      type: String,
      default: "text-gray-500",
    },
  },
  data() {
    return {
      visible: false,
      handleValue: 0,
      appealValue: 0,
      roomId: null,
      userInfo: {},
      handleData: [],
      startData: [],
      activeId: 1,
      timer: "",
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getData();
    // setTimeout(async () => {
    //   await this.getData();
    //   // this.timer = setInterval(async () => {
    //   //   await this.getData();
    //   // }, 5 * 60 * 1000);
    // }, 10 * 1000);
    // // this.timer = setInterval(async () => {
    // //   await this.getData();
    // // }, 5 * 60 * 1000);
  },
  destroyed() {
    // let time = this.timer;
    // this.timer = "";
    // console.log("清除定时器", this.timer, time);
    // clearInterval(time);
  },
  methods: {
    /**
     *  @actions:  获取申诉数据
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/24 22:05
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async getData() {
      console.log("token:", Cookies.get("hankun_cloud_template_token"));
      if (!Cookies.get("hankun_cloud_template_token")) {
        return false;
      }

      this.userInfo = await this.$cloud.get("/login/info");
      this.roomId = this.userInfo.masterRoom?.id;
      if (!this.roomId) {
        this.activeId = 2;
      }
      if (!this.userInfo.no && this.userInfo.role === "teacher") {
        // 先获取一次
        if (this.roomId) {
          await this.getHandleDispose(); // 获取需要班主任老师处理的申诉
        }
        //await this.getStartDispose(); // 获取自身发起申诉
      }
    },

    /**
     *  @actions:  获取任课老师发起的申诉列表
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/16 21:48
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async getHandleDispose() {
      let params = {
        roomId: this.roomId,
        read: "NO",
        isEdit: "NO",
      };
      await this.$cloud
        .get("/statement/list_handle", params)
        .then((res) => {
          this.handleData = res?.list ?? [];
          this.handleValue = this.handleData.length || 0;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    /**
     *  @actions:  获取自身发起的申诉
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/22 22:09
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async getStartDispose() {
      await this.$cloud
        .get("/statement/list_initiate", {
          read: "NO",
        })
        .then((res) => {
          this.startData = res?.list || [];
          this.appealValue = this.startData.length || 0;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    /**
     *  @actions:  修改申诉分
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/22 22:29
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleUpdate(item) {
      this.$refs["appeal"].open(item, this.userInfo);
    },

    /**
     *  @actions:   确认已读
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/22 22:51
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async handleConfirm(item) {
      await this.$confirm("确定将该条申诉处理结果改为已读吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$cloud
          .post("/statement/read", { id: item.id })
          .then(async () => {
            this.$message.success("确认成功！");
            await this.getStartDispose();
          })
          .catch((err) => {
            this.$message.error(err);
          });

        console.log("确定！！！");
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
