<template>
  <img class="h-14" :src="logo" />
</template>
<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      logo: "",
    };
  },
  created() {},
  mounted() {
    this.getSystem();
  },
  methods: {

      getSystem(){
        this.loading = true;
        this.$cloud.get('setting/get',{key:'logo'}).then((res)=>{
          this.loading = false;
          try {
            this.logo =JSON.parse(res).logo;
          } catch ( e ){
            this.logo = '';
          }
        }).catch(err=>{
          this.loading = false;
          this.$message.error(err);
        })
    },
  },
};
</script>
<style lang="scss" scoped></style>
