import DashboardLayout from "@/layout/DashboardLayout";
import AdminboardLayout from "@/layout/AdminboardLayout";
import AuthCoverLayout from "@/layout/AuthCoverLayout";

import adminRouter from "./adminRouter";

export default [
  {
    path: "/login",
    component: AuthCoverLayout,
    name: "loginMain",
    redirect: "/pages/login/login",
    children: [
      {
        path: "/pages/login/login",
        name: "login",
        title: "登录",
        component: () => import("../pages/login/login.vue"),
      },
      {
        path: "/pages/login/ding",
        name: "login",
        title: "钉钉登录",
        component: () => import("../pages/login/ding.vue"),
      },
    ],
  },
  {
    path: "/",
    component: DashboardLayout,
    name: "SelectMain",
    redirect: "/pages/home/home",
    children: [
      {
        path: "/pages/demo/demo",
        name: "demo",
        title: "登录",
        component: () => import("../pages/demo/demo.vue"),
      },
      {
        path: "/pages/home/home",
        name: "SelectClass",
        component: () => import("../pages/select/class.vue"),
      },
      {
        path: "/pages/select/role",
        name: "SelectRole",
        component: () => import("../pages/select/index.vue"),
      },

      {
        path: "/pages/select/score",
        name: "SelectScore",
        component: () => import("../pages/score/index.vue"),
      },

      {
        path: "/pages/setting/setting",
        name: "setting",
        title: "修改密码",
        icon: "el-icon-menu",
        component: () => import("../pages/setting/index.vue"),
      },
    ],
  },
  {
    path: "/admin",
    component: AdminboardLayout,
    name: "homeMain",
    redirect: "/pages/index/index",
    children: adminRouter,
  },
  {
    path: "/mobile",
    redirect: "/mobile/login/index",
    name: "mobileMain",
    component: () => import("../pages/mobile/layout.vue"),
    children: [
      {
        path: "/mobile/login/index",
        name: "mobile",
        title: "移动端",
        component: () => import("../pages/mobile/login/index.vue"),
      },
      {
        path: "/mobile/index/index",
        name: "mobileIndex",
        title: "移动端主页",
        component: () => import("../pages/mobile/index/index.vue"),
      },
      {
        path: "/mobile/password/index",
        name: "password",
        title: "修改密码",
        component: () => import("../pages/mobile/password/index.vue"),
      },
      {
        path: "/mobile/score/index",
        name: "score",
        title: "班级成绩",
        component: () => import("../pages/mobile/score/index.vue"),
      },
    ],
  },
];
