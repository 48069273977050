module.exports = [
  {
    path: "/pages/index/index",
    name: "home",
    title: "首页",
    icon: "el-icon-menu",
    component: () => import("../pages/home/home.vue"),
  },
  {
    path: "/pages/teacher/teacher",
    name: "teacher",
    title: "教师管理",
    icon: "el-icon-menu",
    component: () => import("../pages/teacher/index.vue"),
  },
  // {
  //   path: "/pages/guardian/guardian",
  //   name: "guardian",
  //   title: "家长管理",
  //   icon: "el-icon-menu",
  //   component: () => import("../pages/guardian/index.vue"),
  // },
  {
    path: "/pages/student/student",
    name: "student",
    title: "学生管理",
    icon: "el-icon-menu",
    component: () => import("../pages/student/index.vue"),
  },
  {
    path: "/pages/class/class",
    name: "class",
    title: "班级管理",
    icon: "el-icon-menu",
    component: () => import("../pages/class/index.vue"),
  },
  {
    path: "/pages/subject/subject",
    name: "subject",
    title: "学科管理",
    icon: "el-icon-menu",
    component: () => import("../pages/subject/index.vue"),
  },
  {
    path: "/pages/task/task",
    name: "task",
    title: "任务管理",
    icon: "el-icon-menu",
    component: () => import("../pages/task/index.vue"),
  },
  // {
  //   path: "/pages/schedule/schedule",
  //   name: "schedule",
  //   title: "课程表管理",
  //   icon: "el-icon-menu",
  //   component: () => import("../pages/schedule/index.vue"),
  // },
  {
    path: "/pages/dimension/dimension",
    name: "dimension",
    title: "评价维度管理",
    icon: "el-icon-menu",
    component: () => import("../pages/dimension/index.vue"),
  },
  {
    path: "/pages/target/target",
    name: "target",
    title: "评价指标管理",
    icon: "el-icon-menu",
    component: () => import("../pages/target/index.vue"),
  },
  {
    path: "/pages/complaint/complaint",
    name: "complaint",
    title: "申诉管理",
    icon: "el-icon-menu",
    component: () => import("../pages/complaint/index.vue"),
  },
  {
    path: "/pages/history/class",
    name: "historyClass",
    title: "班级历史评分",
    icon: "el-icon-menu",
    component: () => import("../pages/history/class.vue"),
  },
  {
    path: "/pages/history/student",
    name: "historyStudent",
    title: "学生历史评分",
    icon: "el-icon-menu",
    component: () => import("../pages/history/student.vue"),
  },
  {
    path: "/pages/system/log",
    name: "log",
    title: "系统日志",
    icon: "el-icon-menu",
    component: () => import("../pages/log/index.vue"),
  },
  {
    path: "/pages/system/system",
    name: "system",
    title: "系统设置",
    icon: "el-icon-menu",
    component: () => import("../pages/system/index.vue"),
  },
];
