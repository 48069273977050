<template>
  <el-container class="h-full">
    <div class="bg-header"></div>
    <el-header class="z-10">
      <Header text-color="text-gray-50"></Header>
    </el-header>
    <el-container class="z-10" style="height: calc(100% - 60px)">
      <el-aside width="200px" class="bg-menu bg-white py-5 my-2" >
        <Menu></Menu>
      </el-aside>
      <el-main class="h-full bg-main bg-white my-2 mx-5">
        <router-view class="h-full bg-white"></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
// import Logo from "./components/Logo";
import Menu from "./components/Menu/index.vue";
import Header from "./components/Header";
// import Back from "./components/Back";
import { menuKey } from "@/settings.js";
import { getLocalStorage } from "@/util/storage";

export default {
  components: { Menu, Header },
  /**
   * 数据字段
   * @return {[type]} [description]
   */
  data() {
    return {
      orgInfo: {},
    };
  },
  /**
   * 页面加载时
   * @return {[type]} [description]
   */
  mounted() {
    // this.handleLoadMyOrg();
    let to = getLocalStorage(menuKey);
    if (to) {
      this.$cloud.go(to.path);
    }
  },
  /**
   * 页面调用函数
   * @type {Object}
   */
  methods: {
    /**
     * 获取我的组织
     */
    handleLoadMyOrg() {
      this.$cloud
        .get("/org/my")
        .then((data) => {
          this.orgInfo = data;
          this.$cloud.org_id(data.id);
        })
        .catch((error) => {
          this.$message.error(error)
          console.log(error);
        });
    },
  },
};
</script>
<style>
.bg-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100px;
  background: #409eff;
  border-radius: 0 0 30px 30px;
  z-index: 1;
}

.bg-menu {
  border-radius: 0 30px 30px 0;
  overflow: hidden;
  z-index: 10;
  height: 100%;
}

.bg-main {
  border-radius: 30px 30px;
}

.el-menu-item.is-active:before {
  content: "";
  height: 100%;
  width: 5px;
  background: #409eff;
  position: absolute;
  left: 0;
  top: 0;
}
</style>
