<template>
  <el-container class="h-screen">
    <el-header class="bg-white bg-white" style="height: 64px">
      <div class="h-header">
        <Header></Header>
      </div>
    </el-header>
    <el-main class="h-main">
      <router-view class="h-full"></router-view>
    </el-main>
  </el-container>
</template>

<script>
import Header from "./components/Header";
import { title } from "@/settings.js";

export default {
  components: { Header },
  /**
   * 数据字段
   * @return {[type]} [description]
   */
  data() {
    return {
      title,
      orgInfo: {},
    };
  },
  /**
   * 页面加载时
   * @return {[type]} [description]
   */
  mounted() {
    // this.handleLoadMyOrg();
  },
  /**
   * 页面调用函数
   * @type {Object}
   */
  methods: {
    /**
     * 获取我的组织
     */
    handleLoadMyOrg() {
      this.$cloud
        .get("/org/my")
        .then((data) => {
          this.orgInfo = data;
          this.$cloud.org_id(data.id);
        })
        .catch((error) => {
          this.$message.error(error)
          console.log(error);
        });
    },
  },
};
</script>
<style>
.h-main {
  width: 1200px;
  margin: 0 auto;
  overflow: hidden !important;
}
.h-header {
  width: 1200px;
  margin: 0 auto;
}
</style>
