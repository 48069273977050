import store from "../store";
import router from "./router";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { getToken } from "../utils/auth"; // get token from cookie
NProgress.configure({ showSpinner: false }); // NProgress Configuration

let $iii = 0;

export const routerDefend = (routeMap) => {
  const whiteList = [
    "/login",
    "/login/index",

    "/pages/login/login",
    "/pages/login/ding",

    "/mobile",
    "/mobile/login/index",
  ];
  router.beforeEach(async (to, from, next) => {
    NProgress.start();
    //用于存储路由数据
    if (to.params && Object.keys(to.params).length) {
      localStorage.setItem("routerParams", JSON.stringify(to.params));
    }

    //判断路由是否存在
    function isRouterExist() {
      //循环判断是否路由存在
      return router.getRoutes().find(function (item) {
        //console.log("进入到这里我是G", to.params);
        let params = {};
        if (to.params && Object.keys(to.params).length) {
          params = to.params;
        } else {
          params = JSON.parse(localStorage.getItem("routerParams"));
        }

        //console.log("进入到这里我是G-1", params);

        //判断是否是params类型的结构的数据
        if (params && Object.keys(params).length) {
          // //console.log("进入到这里我是G-2-用于检测多元化 item.path", {
          //   path: item.path,
          //   to: to.path,
          //   params: params,
          // });
          let pathArray = to.path.split("/");
          let itemArray = item.path.split("/");
          if (pathArray.length === itemArray.length) {
            let step = true;
            //进行params的数据结构匹配
            pathArray.forEach((value, index) => {
              if (value !== itemArray[index]) {
                if (value.indexOf(":") === 0) {
                  let key = value.concat(":", "");
                  if (!params[key]) {
                    step = false;
                  }
                }
              }
            });
            return step;
          } else {
            return item.path === to.path;
          }
        } else {
          return item.path === to.path;
        }
      });
    }

    try {
      // determine whether the user has logged in
      const hasToken = getToken();

      // 如果路由已经存在，则直接进入
      if (isRouterExist()) {
        if (hasToken) {
          //console.log("进入到这里我是A", to.path);
          if (to.path === "/login" || to.path === "/pages/login/login") {
            // if is logged in, redirect to the home page
            next({ path: "/" });
            NProgress.done();
          } else {
            let userInfo = $hankun.account.state.userInfo;
            //此处需要判断一个用户
            if (userInfo && userInfo.id && userInfo.username) {
              // console.log("store.getStore().state.userInfo", userInfo);
            } else {
              await $hankun.account.userInfo("account/detail");
            }
            next();
            NProgress.done();
          }
        } else {
          /* has no token*/
          //console.log("进入到这里我是B", to.path);
          if (whiteList.indexOf(to.path) !== -1) {
            if (to.path === "/") {
              //console.log("进入到这里我是B-1", to.path);
              next({ path: "/pages/home/home" });
            } else {
              next();
            }
          } else {
            if (to.path === "/") {
              //console.log("进入到这里我是B-2", to.path);
              next({ path: "/pages/home/home" });
            } else {
              next(`/login?redirect=${to.path}`);
            }
          }
          NProgress.done();
        }
      } else {
        //console.log("进入到这里我是C", to.path, routeMap);
        store.install();
        //路由动态添加
        if (Array.isArray(routeMap)) {
          routeMap.map((item) => {
            // console.log("item", item);
            router.addRoute(item.name, item);
          });
        }

        if (isRouterExist()) {
          $iii = 0;
          next({ path: to.path, query: to.query });
        } else {
          if (to.path === "/") {
            //console.log("进入到这里我是E", to.path);
            next({ path: "/pages/home/home" });
          } else {
            //console.log("进入到这里我是F", to.path);
            next({ path: "/404", query: to.query });
          }
        }
      }
    } catch (error) {
      console.log("查看错误信息", error);
      next();
      NProgress.done();
    }
  });

  router.afterEach(() => {
    // finish progress bar
    NProgress.done();
  });

  //返回路由信息
  return router;
};
