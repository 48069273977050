<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: "App",
  created() {
    this.getSystem();
  },
  methods: {
    getSystem() {
      this.loading = true;
      this.$cloud
        .get("setting/get", { key: "logo" })
        .then((res) => {
          this.loading = false;
          try {
            document.title = JSON.parse(res).title;
          } catch (e) {
            console.log('');
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },
  },
};
</script>
<style>
html,
body,
#app {
  overflow: hidden;
  background-color: #f6f8f9;
}

.el-card__header {
  border-bottom: 0 !important;
}

.el-scrollbar__wrap {
  overflow-x: hidden !important;
}

.el-menu {
  border-right: 0 !important;
}

.el-tree-node__content {
  height: 40px !important;
  line-height: 40px !important;
  border-radius: 10px !important;
}

.el-number input::-webkit-outer-spin-button,
.el-number input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
.el-number input[type="number"] {
  -moz-appearance: textfield;
}
.el-button.is-round {
}
</style>
