import axios from "axios";
import { getToken } from "./auth";
import router from "../router/router";
import store from "../store/user";

const service = axios.create({
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    const token = getToken();
    if (token) {
      // let each request carry token
      // ['Authorization'] is a custom headers key
      // please modify it according to the actual situation
      config.headers.Authorization = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    // do something with request error
    console.log("错误 ", error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    if (response.status >= 200 && response.status < 300) {
      if (
        response.headers["x-pagination-total-count"] ||
        response.headers["X-Pagination-Total-Count"]
      ) {
        return response;
      }
      if (response.data.code === 0) {
        return response.data.data || response.data.msg || "succeed";
      } else {
        //处理40000-50000自动报错
        if (response.data.code >= 40000 && response.data.code < 50000) {
          // Message({
          //     message: response.data.msg || 'Error',
          //     type: 'error',
          //     duration: 5 * 1000
          // })
        }
        if (response.data.code >= 20000 && response.data.code < 20100) {
          store
            .getStore()
            .dispatch("logout")
            .then(() => {
              router.push({
                path: "/login",
              });
            });
        }
        return Promise.reject(response.data);
      }
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    console.log("错误 ", error); // for debug
    if (error.response && error.response.status === 401) {
      store
        .getStore()
        .dispatch("logout")
        .then(() => {
          window.location.reload();
        });
    } else {
      return Promise.reject(error?.response?.data?.msg || "服务器繁忙");
    }
  }
);

export default service;
