<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: "AuthCoverLayout",
  /**
   * 调用组建
   * @type {Object}
   */
  components: {},
  /**
   * 组建属性
   * @type {Object}
   */
  props: {},
  /**
   * 数据字段
   * @return {[type]} [description]
   */
  data() {
    return {};
  },
  /**
   * 计算属性
   * @type {Object}
   */
  computed: {},
  /**
   * 数据监听
   * @type {Object}
   */
  watch: {},
  /**
   * 页面创建时
   * @return {[type]} [description]
   */
  created() {},
  /**
   * 页面加载时
   * @return {[type]} [description]
   */
  mounted() {},
  /**
   * 页面调用函数
   * @type {Object}
   */
  methods: {},
};
</script>
<style scoped></style>
