import store from "./store";
import request from "../utils/request";
import { getToken, setToken, removeToken } from "../utils/auth";

const mutations = {};

const actions = {
  /**
   * 执行用户登录
   * @param commit
   * @param userInfo
   * @returns {Promise<unknown>}
   */
  login({ commit }, userInfo) {
    const { username, password } = userInfo;
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password })
        .then((response) => {
          const { data } = response;
          commit("SET_TOKEN", data.token);
          setToken(data.token);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

/**
 * 导出菜单
 */
export default {
  install() {
    const storeName = "menus_application";
    // 判断是否注册了模型
    if (!store.hasModule(storeName)) {
      store.registerModule([storeName], {
        namespaced: true,
        state: {
          routes: [],
          userInfo: {},
          mainPath: "/",
        },
        mutations,
        actions,
      });
    }
  },
  uninstall() {
    const storeName = "menus_application";
    // 判断要注销的模块是否存在
    if (store.hasModule(storeName)) {
      store.unregisterModule([storeName]);
    }
  },
  getStore() {
    const storeName = "menus_application";
    return {
      state: store.state[storeName],
      commit(type, payload, options) {
        type = storeName + "/" + type;
        return store.commit(type, payload, options);
      },
      dispatch(type, payload) {
        type = storeName + "/" + type;
        return store.dispatch(type, payload);
      },
    };
  },
};
