import request from "../utils/request";
import { getToken } from "../utils/auth";
import vm from "../utils/vm";
import router from "../router/router";

export default class Cloud {
  $org_id = "";

  /**
   * 执行登录操作
   * @param url
   * @param userInfo
   * @returns {Promise<unknown>}
   */
  login(url, userInfo) {
    return $hankun.account.login(url, userInfo);
  }

  /**
   * 执行VM
   */
  vm(code, common) {
    common = Object.assign(
      {
        setTimeout: setTimeout,
      },
      common
    );
    console.log("code", code, common);
    //设置内容
    vm.createContext(common);
    // 在执行上下文运行
    return vm.runInContext(code, common);
  }

  org_id(value) {
    this.$org_id = value;
  }

  userInfo() {
    return $hankun.account.state.userInfo;
  }

  request(options) {
    return request(options);
  }

  /**
   * 获取设置Token
   * @returns {*}
   */
  token() {
    return getToken();
  }

  /**
   * 数据POST方法
   * @param url
   * @param params
   * @param data
   * @returns {*}
   */
  upload(url, params = null, data = null) {
    if (url.indexOf("/") === 0) {
      url = url.slice(1);
    }
    //设置数据结构
    if (data === null) {
      data = params;
      params = null;
    }
    //重置URL地址  临时加本地地址
    url = `/api/` + url;
    return request({
      url: url,
      method: "POST",
      headers: {
        "Content-type": "multipart/form-data",
        "x-api-type": "app",
      },
      params: _.assign({}, params),
      data: data,
    });
  }

  /**
   * 数据POST方法
   * @param url
   * @param params
   * @param data
   * @returns {*}
   */
  post(url, params = null, data = null) {
    if (url.indexOf("/") === 0) {
      url = url.slice(1);
    }
    //设置数据结构
    if (data === null) {
      data = params;
      params = null;
    }
    //重置URL地址
    if (url.indexOf("http") === -1) {
      url = `/api/` + url;
    }
    return request({
      url: url,
      method: "POST",
      headers: {
        "x-api-type": "app",
      },
      params: _.assign({}, params),
      data: data,
    });
  }

  /**
   * 数据GET方法
   * @param url
   * @param params
   * @returns {*}
   */
  get(url, params = null) {
    if (url.indexOf("/") === 0) {
      url = url.slice(1);
    }
    //重置URL地址
    if (url.indexOf("http") === -1) {
      url = `/api/` + url;
    }
    return request({
      url: url,
      method: "POST",
      headers: {
        "x-api-type": "app",
      },
      params: _.assign({}, params),
    });
  }

  /**
   * 数据POST方法
   * @param url
   * @param size
   * @param page
   * @param params
   * @param data
   * @returns {*}
   */
  page(url, { size = 10, page = 1 }, params = null, data = null) {
    if (url.indexOf("/") === 0) {
      url = url.slice(1);
    }
    //设置数据结构
    if (data === null) {
      data = params;
      params = null;
    }
    //重置URL地址
    url = `/api/` + url;
    return request({
      url: url,
      method: "POST",
      headers: {
        "x-api-type": "app",
      },
      params: _.assign(
        {},
        {
          page,
          size,
        },
        params
      ),
      data: data,
    });
  }

  /**
   * 跳转
   */
  go(url, query = null) {
    if (Object.prototype.toString.call(url) === "[object Object]") {
      if (url.query) {
        url.query = Object.assign(url.query, query);
      }
    } else {
      url = {
        path: url,
        query,
      };
    }
    console.log("需要跳转的界面", url);
    router.push(url, () => {});
  }
}
