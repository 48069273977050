<!--
 * ======================================
 * 说明： 评分申诉
 * 作者： Silence
 * 文件： appeal.vue
 * 日期： 2023/5/9 23:19
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <Dialog
    :title="`【${title}】评分修改`"
    width="600px"
    height="360px"
    v-model="visible"
    @handleConfirm="handleConfirm"
  >
    <el-form
      v-loading="loading"
      :model="editForm"
      :rules="rule"
      ref="editForm"
      label-width="80px"
    >
      <el-row :gutter="20">
        <el-col>
          <el-form-item label="申诉老师">
            {{ params.User ? params.User.username : "" }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col>
          <el-form-item label="申诉原因">
            {{ params.reason }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="评价内容" prop="indicatorId">
            <el-select v-model="editForm.mark_id" style="width: 100%" disabled>
              <el-option
                v-for="(item, index) in options"
                :value="item.id"
                :label="item.no + item['Subject'].name"
                :key="index"
              >
                <span style="float: left">{{ item.no }}{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.targetedList
                    ? item.targetedList.map((part) => part.name).join(" ")
                    : ""
                }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item :label="`评分`" prop="score">
            <el-input-number style="width: 100%" v-model="editForm.score">
            </el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </Dialog>
</template>
<script>
import Dialog from "@/pages/components/dialog";
export default {
  name: "",
  components: { Dialog },
  props: {
    userinfo: Object,
    roomId: [String, Number],
  },
  data() {
    return {
      editForm: {},
      params: {},
      title: "",
      visible: false,
      loading: false,
      options: [],
      scored: {}, // 选择的申诉内容
      subjects: [],
      loginInfo: {},
      rule: {
        score: [
          {
            required: true,
            trigger: "blur",
            message: "请输入更新分数！",
          },
        ],
      },
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    /**
     *  @actions:  打开窗口
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 23:19
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async open(data, loginInfo) {
      this.loginInfo = loginInfo;
      this.params = data;
      this.title = data.Student.username;
      this.visible = true;
      await this.getOptions();
    },

    /**
     *  @actions:  获取申诉目标选项
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 23:43
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async getOptions() {
      this.$cloud
        .get("/mark/nolist_by_room_id", { roomId: this.params.room_id })
        .then(async (res) => {
          this.options = res;
          await this.handleSelectOption();
        }).catch(err=>{
          this.$message.error(err)
      });
    },

    /**
     *  @actions:  选择的评价内容
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/19 13:39
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleSelectOption() {
      this.loading = true;
      this.$cloud
        .get("/statement/get_edit_mark", {
          markId: this.params["mark_id"],
          studentId: this.params["Student"]?.id,
          roomId: this.params.room_id,
          teacherId: this.params["User"]?.id,
        })
        .then((res) => {
          this.loading = false;
          this.editForm = {
            mark_id: res.mark?.id,
            score: res.record?.score,
            recordId: res.record?.id,
          };
          // this.options = res;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err);
        });
    },

    // // 输入验证
    handleVerify(callback) {
      this.$cloud
        .get("/mark/verify", {
          roomId: this.params.room_id,
          mark_id: this.editForm.mark_id,
          value: this.editForm.score,
        })
        .then((res) => {
          if (res && typeof res === "object") {
            this.$message.error(
              `占比 ${res.min}-${res.max}占比${res.ratio} 打分已饱和`
            );
          } else if (res) {
            callback();
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    /**
     *  @actions:  确定申诉
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/9 23:23
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleConfirm() {
      this.$refs["editForm"].validate((vaild) => {
        if (vaild) {
          this.handleVerify(() => {
            this.$confirm("确定要更新当前申诉的分数吗?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              let params = {
                recordId: this.editForm.recordId,
                statementId: this.params.id,
                score: this.editForm.score,
              };
              this.loading = true;
              this.$cloud
                .post("/statement/edit", params)
                .then(() => {
                  this.$message.success("更新成功！");
                  this.visible = false;
                  this.loading = false;
                })
                .catch((err) => {
                  this.$message.error(err);
                  this.loading = false;
                });
            });
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
