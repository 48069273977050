<template>
  <div class="h-14 flex items-center">
    <div class="flex-1 flex items-center pt-2">
      <Logo v-if="isLogo"></Logo
      ><span class="px-2 text-lg" :class="textColor"> • 智慧评分管理系统</span>
    </div>

    <div class="flex-1 flex items-center justify-end">
      <el-avatar size="small" :src="squareUrl"></el-avatar>
      <el-button type="text" :class="textColor" class="px-2">{{
        userinfo["username"]
      }}</el-button>
      <Message :text-color="textColor"></Message>
<!--      <el-button-->
<!--        type="text"-->
<!--        :class="textColor"-->
<!--        icon="el-icon-s-platform"-->
<!--        @click="onBigScreen"-->
<!--      >大屏</el-button-->
<!--      >-->
      <el-button
        type="text"
        :class="textColor"
        icon="el-icon-ali-setting"
        @click="handleSetting"
        >设置</el-button
      >
      <el-button
        type="text"
        :class="textColor"
        icon="el-icon-ali-exit"
        @click="handleOutLogin"
        >退出</el-button
      >
      <!--      <label>{{ userinfo["username"] }}</label>-->
      <!--      <el-dropdown @command="handleCommand">-->
      <!--        <el-avatar shape="square" size="medium" :src="squareUrl"></el-avatar>-->
      <!--        <el-dropdown-menu slot="dropdown">-->
      <!--          <el-dropdown-item command="home">回到主页</el-dropdown-item>-->
      <!--          <el-dropdown-item command="setting">个人设置</el-dropdown-item>-->
      <!--          <el-dropdown-item command="logout">退出登录</el-dropdown-item>-->
      <!--        </el-dropdown-menu>-->
      <!--      </el-dropdown>-->
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import Logo from "../Logo/index.vue";
import Message from "../Message/index.vue";
import { clearLocalStorage } from "@/util/storage";

export default {
  name: "",
  components: { Logo, Message },
  props: {
    isLogo: {
      type: Boolean,
      default: true,
    },
    textColor: {
      type: String,
      default: "text-gray-500",
    },
  },
  data() {
    return {
      squareUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      userinfo: {},
    };
  },
  created() {},
  mounted() {
    this.userinfo = this.$cloud.userInfo();
  },
  methods: {
    // 大屏
    onBigScreen() {
      window.open('/bigScreen/index.html', "_blank");
    },
    
    handleOutLogin() {
      this.$confirm("您确定退出系统吗？", "退出系统", {
        type: "warning",
      }).then(() => {
        Cookies.remove("hankun_cloud_template_token");
        clearLocalStorage();
        this.$cloud.go("/pages/login/login");
      }).catch(()=>{
        // this.$message.error(err)
      });
    },

    /**
     *  @actions:  跳转设置
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/5/11 10:39
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    handleSetting() {
      this.$cloud.go("/pages/setting/setting");
    },
  },
};
</script>
