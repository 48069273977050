export function setLocalStorage(key, data) {
  if (key && data && typeof data == "object") {
    localStorage.setItem(key, JSON.stringify(data));
  } else {
    localStorage.setItem(key, data);
  }
}

export function getLocalStorage(key) {
  let ret = "";
  try {
    ret = JSON.parse(localStorage.getItem(key));
  } catch (err) {
    ret = localStorage.getItem(key);
  }
  return ret;
}

export function removeLocalStorage(key) {
  localStorage.removeItem(key);
}

export function clearLocalStorage() {
  localStorage.clear();
}
